header {
    padding-top: 12px !important;
}
.dark-text {
    color: #505050;
}
.gold {
    background-color: #413ed2 !important;
    color: white !important;
}
.gold-link {
    color: #413ed2 !important
}
.spacer-200 {
    min-height: 200px;
}
.spacer-100 {
    min-height: 100px;
}
.center {
    text-align: center;
    /*margin: auto;*/
}
.hide {
    display: none;
}
.left-margin-25 {
    margin-left: 15px !important;
}
.copyright {
    font-size: .75rem;
}
// Header links TODO update these with new classes to fix the scss compile issue on heroku
.MuiToolbar-root.MuiToolbar-regular.makeStyles-container-9.MuiToolbar-gutters {
    padding-left: 0 !important;
    margin-left: 15px !important;
}
.makeStyles-container-9 {
    max-width: 100% !important;
}
.MuiToolbar-root.MuiToolbar-regular.makeStyles-container-4.MuiToolbar-gutters,
.MuiToolbar-root.MuiToolbar-regular.makeStyles-container-14.MuiToolbar-gutters,
.MuiToolbar-root.MuiToolbar-regular.jss9.MuiToolbar-gutters,
.MuiToolbar-root.MuiToolbar-regular.jss14.MuiToolbar-gutters,
.MuiToolbar-root.MuiToolbar-regular.jss4.MuiToolbar-gutters {
    max-width: 100% !important;
}

// Card header
.makeStyles-primaryCardHeader-288,
.jss282.jss288.jss5 {
    color: #fff;
    background: linear-gradient(60deg, #223a71, #152646) !important;
}

/*.MuiButton-root:hover {*/
/*    background-color: transparent !important;*/
/*    border-radius: 25% !important;*/
/*}*/

/* the original drawer button */
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
    position: fixed;
    right: 8px;
    /*z-index: 99;*/
}
button.burger {
    border-radius: 50%;
    width: 50px !important;
    height: 55px !important;
    background-color: transparent;
}

/* contact form */

/*.text-area {*/
/*    height: 100px;*/
/*}*/

/* header logo */
/*a.MuiButtonBase-root.MuiButton-root.makeStyles-title-11.MuiButton-text {*/
/*    padding-left: 0;*/
/*}*/
/*.MuiToolbar-root.MuiToolbar-regular.makeStyles-container-9.MuiToolbar-gutters {*/
/*    padding-left: 5px;*/
/*}*/

@media screen and (max-width: 750px) {
    .hide-mobile {
        display: none !important;
    }
    .show-mobile {
        display: block;
    }
}

@media (min-width: 960px) {
    .login-card {
        max-width: 350px !important;
    }
}
